<template>
  <div class="req-temp-container integration-container">
    <vs-card class="mt-6">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-medium">Integrations</h3>
        </div>
      </div>

      <p class="text-gray-font" v-html="description"></p>

      <div class="mt-8">
        <div class="mb-8">
          <h4>Your Box & Dice account</h4>
          <hr class="my-2" />
          <p class="text-sm mb-4">Please provide the domain you use to access Box & Dice</p>
          <div class="w-1/2">
            <vs-input
              class="w-2/3 no-max-width text-gray"
              type="text"
              v-model="boxAndDice.domain"
              name="domain"
              v-validate="'required|urlFormat'"
              @change="validateDomain"
            />
            <span class="text-danger text-sm" v-show="errors.has('domain')">{{ errors.first("domain") }}</span>
          </div>
        </div>
        <div class="mb-16">
          <p class="text-sm mb-4">Please provide your API key from <b class="uppercase">{{ activeIntroducer.name }}</b> (the {{ appName }} integration one) and paste it below</p>
          <div class="w-1/2">
            <vs-input
              class="w-2/3 no-max-width text-gray"
              type="text"
              v-model="boxAndDice.apiKey"
              name="apiKey"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('apiKey')">{{ errors.first("apiKey") }}</span><br />
          </div>
          <vs-button action-btn class="mt-4" @click="testConnection" :disabled="testDisabled" v-round>Test your settings</vs-button>
          <img v-if="showIcon && testResponseIcon == 'success'" class="icon-control ml-8 align-middle" :src="successCircle" alt="alert" />
          <img v-if="showIcon && testResponseIcon == 'error'" class="icon-control ml-8 align-middle" :src="errorCircle" alt="alert" />
          <span class="ml-4 text-gray">{{ testResponseMessage }}</span><br />
          <span class="text-danger text-sm" v-show="errors.has('connection')">{{ errors.first("connection") }}</span>
        </div>
        <div class="mb-8">
          <h4>Default settings</h4>
          <p class="text-sm mb-4">Please select a default bank account for {{ appName }} to disburse funds into (for approved VPA agreements). Where no other information is available, we'll disburse to this account.</p>
          <div class="input-grp">
            <vs-select
              v-model="boxAndDice.disbursement"
              name="disbursementAccount"
              id="disbursementAccount"
              class="w-full selectExample pr-8 mb-0"
              v-validate="'required'"
              :placeholder="'Select account'"
            >
              <vs-select-item
                :key="index"
                :value="item.bankId"
                :text="item.displayName ? item.displayName : item.accountName"
                v-for="(item, index) in bankList"
              />
            </vs-select>
          </div>
          <span class="text-danger text-sm" v-show="errors.has('disbursementAccount')">{{ errors.first("disbursementAccount") }}</span>
        </div>
        <div class="mb-8">
          <p class="text-sm mb-4">Please select a default recipient/s for automated notifications about your vendor agreements (optional)</p>
          <div class="w-1/2">
            <div v-for="(newEmail, index) in boxAndDice.notifications" :key="index" class="flex flex-row mb-2">
              <div class="mr-2 w-1/2">
                <vs-input
                  size="large"
                  v-model="newEmail.email"
                  data-vv-validate-on="blur"
                  data-vv-as="email address"
                  :name="'defaultNotification'+index"
                  :id="'notification'+index"
                  class="w-full text-gray"
                  v-validate="'email'"
                  autocomplete="off"
                />
                <span class="text-danger text-sm" v-show="errors.has('defaultNotification'+index)">{{ errors.first("defaultNotification"+index) }}</span>
              </div>
              <feather-icon v-if="deleteEmail(index)" icon="Trash2Icon" height="18px" @click="removeEmail(index)" class="text-grey integration-icon pt-2" />
            </div>
            <span @click="addEmail" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
          </div>
        </div>
        <div :class="showDisbursementOverrideOption ? 'mb-8' : 'mb-16'">
          <p class="text-sm mb-4">Please select a default recipient/s for automated remittance advice per vendor agreement (optional)</p>
          <div class="w-1/2">
            <div v-for="(remittanceEmail, index) in boxAndDice.remittance" :key="index" class="flex flex-row mb-2">
              <div class="mr-2 w-1/2">
                <vs-input
                  size="large"
                  v-model="remittanceEmail.email"
                  data-vv-validate-on="blur"
                  data-vv-as="email address"
                  :name="'defaultRemittance'+index"
                  :id="'remittance'+index"
                  class="w-full text-gray"
                  v-validate="'email'"
                  autocomplete="off"
                />
                <span class="text-danger text-sm" v-show="errors.has('defaultRemittance'+index)">{{ errors.first("defaultRemittance"+index) }}</span>
              </div>
              <feather-icon v-if="deleteDefaultRemittance(index)" icon="Trash2Icon" @click="removeDefaultRemittance(index)" class="text-grey integration-icon pt-2" />
            </div>
            <span @click="addDefaultRemittance" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
          </div>
        </div>
        <div class="mb-16" v-if="showDisbursementOverrideOption">
          <p>Schedule disbursements for VPA agreements based on campaign start date (max 60 days from approval) instead of immediately on approval</p>
          <div class="flex w-full my-4">
            <label for="">Allow disbursement date override</label>
            <vs-switch class="ml-4" name="allowDisbursementDateOverride" v-model="boxAndDice.allowDisbursementDateOverride" />
          </div>
        </div>
        <div class="mb-8">
          <div class="flex w-full my-4">
            <label for="overrideOfficeSettings">Configure settings for each office (optional)</label>
            <vs-switch class="ml-4" name="overrideOfficeSettings" v-model="boxAndDice.overrideOfficeSettings" @input="handleDefaultSettings" />
          </div>
          <p class="text-sm mb-4">These settings will override the defaults above. If no value is entered, we won't send an email.</p>
          <div v-if="boxAndDice.overrideOfficeSettings">
            <vs-table :data="boxAndDice.offices" class="stripes no-row-border-table payment-request-table payment-activity-table">
              <template slot="thead">
                <vs-th width="15%">
                  <label class="m-0 p-0 font-normal">Office</label>
                </vs-th>
                <vs-th width="25%">
                  <label class="m-0 p-0 font-normal">Disbursement account</label>
                </vs-th>
                <vs-th width="30%">
                  <label class="m-0 p-0 font-normal">Notifications</label>
                </vs-th>
                <vs-th width="30%">
                  <label class="m-0 p-0 font-normal">Remittance</label>
                </vs-th>
              </template>
              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td>
                    <p>{{ tr.name }}</p>
                  </vs-td>
                  <vs-td>
                    <vs-select
                      v-model="tr.disbursement"
                      :name="'officeDisbursementAccount'+indextr"
                      id="officeDisbursementAccount"
                      :placeholder="'Select account'"
                      class="w-full selectExample pr-8"
                      v-validate="'required'"
                      data-vv-as="disbursement account"
                    >
                      <vs-select-item
                        :key="index"
                        :value="item.bankId"
                        :text="item.displayName ? item.displayName : item.accountName"
                        v-for="(item, index) in bankList"
                      />
                    </vs-select>
                    <span class="text-danger text-sm" v-show="errors.has('officeDisbursementAccount'+indextr)">{{ errors.first("officeDisbursementAccount"+indextr) }}</span>
                  </vs-td>
                  <vs-td>
                    <div v-for="(notificationEmail, index) in tr.notifications" :key="index + 0.5" class="flex flex-row mb-2">
                      <div class="mr-2">
                        <vs-input
                          size="large"
                          v-model="notificationEmail.email"
                          data-vv-validate-on="blur"
                          data-vv-as="email address"
                          :name="'notificationEmail'+indextr+index"
                          :id="'notificationEmail'+indextr+index"
                          class="w-full text-gray"
                          v-validate="'email'"
                          autocomplete="off"
                        />
                        <span class="text-danger text-sm" v-show="errors.has('notificationEmail'+indextr+index)">{{ errors.first("notificationEmail"+indextr+index) }}</span>
                      </div>
                      <feather-icon v-if="deleteOfficeNotificationEmail(indextr)" icon="Trash2Icon" @click="removeOfficeNotificationEmail(indextr, index)" class="text-grey integration-icon pt-2" />
                    </div>
                    <span @click="addOfficeNotificationEmail(indextr)" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
                  </vs-td>
                  <vs-td>
                    <div v-for="(remittanceEmail, index) in tr.remittance" :key="index + 0.15" class="flex flex-row mb-2">
                      <div class="mr-2">
                        <vs-input
                          size="large"
                          v-model="remittanceEmail.email"
                          data-vv-validate-on="blur"
                          data-vv-as="email address"
                          :name="'remittanceEmail'+indextr+index"
                          :id="'remittanceEmail'+indextr+index"
                          class="w-full text-gray"
                          v-validate="'email'"
                          autocomplete="off"
                        />
                        <span class="text-danger text-sm" v-show="errors.has('remittanceEmail'+indextr+index)">{{ errors.first("remittanceEmail"+indextr+index) }}</span>
                      </div>
                      <feather-icon v-if="deleteOfficeRemittanceEmail(indextr)" icon="Trash2Icon" @click="removeOfficeRemittanceEmail(indextr, index)" class="text-grey integration-icon pt-2" />
                    </div>
                    <span @click="addOfficeRemittanceEmail(indextr)" class="text-link flex flex-row pt-2"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
      <div class="popup-action-btn-container pb-8 pt-6 bottom-0 bg-white">
        <div class="pt-8 flex justify-end">
          <vs-button size="large" @click="addIntroducer()" action-btn v-round :disabled="errors.items.length > 0">{{ (action == "edit") ? "Save" : "Confirm" }}</vs-button>
          <vs-button @click="goBack()" size="large" action-btn class="only-border-btn ml-2" v-round>Cancel</vs-button>
        </div>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import S3ImageGenerator from "../../components/S3ImageGenerator";

const successCircle = require("@/assets/images/success-circle.svg");
const errorCircle = require("@/assets/images/cancel.svg");

export default {
  components: { PlusIcon, S3ImageGenerator },

  data() {
    return {
      appName: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello",
      action: "",
      description: "",
      introducerId: "",
      merchantId: "",
      boxAndDice: {
        introducerId: "",
        domain: "",
        apiKey: "",
        overrideOfficeSettings: false,
        offices: [],
        disbursement: "",
        notifications: [{ email: "" }],
        remittance: [{ email: "" }],
      },
      testDisabled: false,
      testResponseIcon: "",
      testResponseMessage: "",
      activeIntroducer: {},
      bankList: [],
      showIcon: false,
      successCircle: successCircle,
      errorCircle: errorCircle,
      isConnectionValid: false,
      merchant: {},
      oldDomain: "",
      oldApiKey: "",
      nextUrl: "",
      product: null
    };
  },

  computed: {
    showDisbursementOverrideOption() {
      return (this.product && this.product.allowDisbursementDateOverride);
    },

    user() {
      return this.$store.state.AppActiveUser;
    },

    partnerId() {
      return (this.user.userType === "admin") ? this.user._id : this.user.partnerId;
    },

    isDomainUpdated() {
      if (this.boxAndDice.domain) {
        const old = new URL(this.oldDomain);

        let newDomain = this.boxAndDice.domain;

        if (!newDomain.startsWith("http://") && !newDomain.startsWith("https://")) {
          newDomain = "https://" + newDomain;
        }
        newDomain = new URL(newDomain);

        return !(old.hostname == newDomain.hostname);
      } else {
        return false;
      }
    },

    isApiKeyUpdated() {
      if (this.boxAndDice.apiKey) {
        return !(this.oldApiKey == this.boxAndDice.apiKey);
      } else {
        return false;
      }
    }
  },

  mounted() {
    this.$validator.extend("urlFormat", {
      getMessage: () =>
        "The domain field is not a valid url.",
      validate: value =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null
    });
    this.getBoxAndDiceProduct();
    this.action = this.$route.params.type;
    this.introducerId = this.$route.params.introducerId;
    this.checkAdminLogin();
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getIntroducerByMerchantId();
  },

  methods: {
    ...mapActions("linkRequest", ["createIntroducerLinkRequest"]),
    ...mapActions("partner", ["testBoxAndDiceConnection", "updateBoxAndDiceSettings"]),
    ...mapActions("introducer", ["fetchIntroducerMerchantLink"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("admin", ["checkLogin"]),
    ...mapActions("setting", ["fetchBoxAndDiceProduct"]),

    async getBoxAndDiceProduct() {
      await this.fetchBoxAndDiceProduct().then((res) => {
        this.product = res.data.data;
      });
    },

    async getIntroducerByMerchantId() {
      await this.fetchIntroducerMerchantLink({ merchantId: this.partnerId, introducerId: this.introducerId }).then((res) => {
        this.activeIntroducer = res.data.data;

        if (this.action == "create" && !this.activeIntroducer.isBoxDiceEnabled) {
          this.showToastMessage("Error", "Introducer has not been enabled for Box & Dice integration", "error");
          this.goBack();
        } else if (this.action == "edit" && !this.activeIntroducer.linkRequest) {
          this.showToastMessage("Error", "Introducer has not been linked with your merchant", "error");
          this.goBack();
        }

        if (this.action == "create") {
          this.description = `You're about to <b>enable</b> the integration with <b class="uppercase">${this.activeIntroducer.name}</b>, allowing <b class="uppercase">${this.activeIntroducer.name}</b> to access your account to create requests, receive updates, etc. Check our help centre for further info on the settings below.`;
        } else if (this.action == "edit") {
          this.description = "Be careful when editing these details - changes will be applied immediately, incorrect authentication details will prevent your integration from operating";
        }

        this.getBanks();
      }).catch(ex => {
        this.showToastMessage("Introducers", ex.data.message, "error");
        this.goBack();
      });
    },

    async checkAdminLogin() {
      this.$vs.loading();
      await this.checkLogin().then(result => {
        this.$vs.loading.close();
        this.merchant = result.data.data;

        if (this.action == "edit") {
          this.handleEdit();
        }
      });
    },

    async handleEdit() {
      const connection = this.merchant.boxAndDice.connections.find((item) => item.introducerId == this.introducerId);

      if (connection) {
        this.oldDomain = connection.domain;
        this.oldApiKey = connection.apiKey;

        let merchantOffices = [...connection.offices];
        const defaultConnection = merchantOffices.find((item) => item.name == "default");
        const existingOfficeIds = merchantOffices.map(item => item.officeId).filter(item => item);

        if (connection.overrideOfficeSettings) {
          const data = {
            partnerId: this.partnerId,
            payload: {
              domain: connection.domain,
              apiKey: connection.apiKey,
              merchantId: this.merchantId,
            }
          };

          this.$vs.loading();
          await this.testBoxAndDiceConnection(data).then((result) => {
            const updatedOffices = result.data.data.data; // offices from latest api call
            const updatedOfficeIds = updatedOffices.map(item => item.id);

            for (let i = 0; i < updatedOffices.length; i++) {
              const officeData = updatedOffices[i];
              const officeIndex = merchantOffices.findIndex((item) => item.officeId == officeData.id); // get index of office as per current configuration

              // if office id not found, add office to array
              if (officeIndex < 0) {
                merchantOffices.push({
                  name: officeData.name,
                  officeId: officeData.id,
                  disbursement: defaultConnection.disbursement || "",
                  notifications: defaultConnection.notifications || [],
                  remittance: defaultConnection.remittance || [],
                });
              } else if (officeIndex >= 0 && (merchantOffices[officeIndex].name != officeData.name)) { // if office exists, but office name is updated, update name in array
                merchantOffices[officeIndex].name = officeData.name;
              }
            }

            // get array of office id from existing offices that doesn't exist in updated list
            const deletedOffice = existingOfficeIds.filter(id => !updatedOfficeIds.includes(id));

            // remove deleted office from merchantOffices array
            for (let i = 0; i < deletedOffice.length; i++) {
              const removeIndex = merchantOffices.findIndex((item) => item.officeId == deletedOffice[i]);

              if (removeIndex >= 0) {
                merchantOffices.splice(removeIndex, 1);
              }
            }
          }).catch((ex) => {
            this.$vs.loading.close();

            this.$vs.notify({
              title: "Box and dice integration",
              text: ex.data.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          });
        }

        const offices = merchantOffices.filter((item) => item.name != "default");
        const defaultNotification = defaultConnection.notifications.filter((item) => item);
        const defaultRemittance = defaultConnection.remittance.filter((item) => item);

        this.boxAndDice = {
          domain: connection.domain,
          apiKey: connection.apiKey,
          introducerId: connection.introducerId,
          disbursement: defaultConnection ? defaultConnection.disbursement : "",
          notifications: defaultNotification.length ? defaultNotification.map((item) => { return { email: item }}) : [{ email: "" }],
          remittance: defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item }}) : [{ email: "" }],
          overrideOfficeSettings: connection.overrideOfficeSettings,
          allowDisbursementDateOverride: connection.allowDisbursementDateOverride,
          offices: offices.map((item) => {
            return {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: item.notifications.length ? item.notifications.map(item => { return { email: item } }) : [{ email: "" }],
              remittance: item.remittance.length ? item.remittance.map(item => { return { email: item } }) : [{ email: "" }],
            }
          })
        }

        this.nextUrl = connection.nextUrl;
        this.$vs.loading.close();
      }
    },

    goBack() {
      this.$router.push({ name: "integrations" });
    },

    async addIntroducer() {
      let obj = {
        merchantId: this.partnerId,
        introducerId: this.introducerId,
      };

      if (this.activeIntroducer.isBoxDiceEnabled) {
        const validate = await this.$validator.validateAll();

        if (!validate || this.errors.length) {
          return false;
        }

        if ((this.action == "create" && !this.isConnectionValid) || (this.action == "edit" && (this.isDomainUpdated || this.isApiKeyUpdated) && !this.isConnectionValid)) {
          this.errors.add({
            field: "connection",
            msg: "Please test connection before proceeding."
          });
          return false;
        }

        obj.boxAndDice = {
          introducerId: this.introducerId,
          domain: this.boxAndDice.domain,
          apiKey: this.boxAndDice.apiKey,
          overrideOfficeSettings: this.boxAndDice.overrideOfficeSettings,
          allowDisbursementDateOverride: this.boxAndDice.allowDisbursementDateOverride,
          offices: []
        };

        if (this.nextUrl) {
          obj.boxAndDice.nextUrl = this.nextUrl;
        }

        obj.boxAndDice.offices.push({
          name: "default",
          disbursement: this.boxAndDice.disbursement,
          notifications: this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item),
          remittance: this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item),
        });

        if (this.boxAndDice.overrideOfficeSettings && this.boxAndDice.offices && this.boxAndDice.offices.length) {
          this.boxAndDice.offices.forEach((item) => {
            const payload = {
              officeId: item.officeId,
              name: item.name,
              disbursement: item.disbursement,
              notifications: item.notifications.length ? item.notifications.map(item => { return item.email }).filter((item) => item) : [],
              remittance: item.remittance.length ? item.remittance.map(item => { return item.email }).filter((item) => item) : [],
            }

            obj.boxAndDice.offices.push(payload);
          })
        }

        obj.boxAndDice = JSON.stringify(obj.boxAndDice);
      }

      this.$vs.loading();

      if (this.action == "create") {
        await this.createIntroducerLinkRequest(obj).then((res) => {
          this.$vs.loading.close();
          this.showToastMessage(res.data.title, res.data.message, "success");
          this.goBack();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Link request", ex.data.message, "error");
        });
      } else if (this.action == "edit") {
        await this.updateBoxAndDiceSettings(obj).then((res) => {
          this.$vs.loading.close();
          this.showToastMessage(res.data.title, res.data.message, "success");
          this.goBack();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.showToastMessage("Integration settings", ex.data.message, "error");
        });
      }
    },

    async getBanks() {
      await this.fetchBanksAccountByPartnerId(this.partnerId).then((result) => {
        if (result) {
          const banksList = result.data.data;
          this.bankList = banksList.filter(item => item.isUsedForSettlement).map((item) => {
            return {
              bankId: item._id,
              displayName: item.displayName ? item.displayName : item.accountName,
              remittanceAdviceEmail: item.remittanceAdviceEmail ? [item.remittanceAdviceEmail] : []
            };
          });
        }
      }).catch((ex) => {
        this.$vs.notify({
          title: "Bank account",
          text: "We are unable to process your request at this time.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right"
        });
      });
    },

    removeEmail(index) {
      this.boxAndDice.notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultNotification" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    addEmail() {
      this.boxAndDice.notifications.push({ email: "" });
    },

    deleteEmail() {
      return this.boxAndDice.notifications.length > 1;
    },

    addDefaultRemittance() {
      this.boxAndDice.remittance.push({ email: "" });
    },

    removeDefaultRemittance(index) {
      this.boxAndDice.remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "defaultRemittance" + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },

    deleteDefaultRemittance() {
      return this.boxAndDice.remittance.length > 1;
    },

    async testConnection() {
      let error = false;

      if (this.errors.has("connection")) {
        this.errors.remove("connection");
      }

      if (!this.boxAndDice.domain) {
        error = true;
        this.errors.add({
          field: "domain",
          msg: "Please enter a valid domain"
        });
      }

      if (!this.boxAndDice.apiKey) {
        error = true;
        this.errors.add({
          field: "apiKey",
          msg: "Please enter a api key"
        });
      }

      if (error) {
        return;
      }

      this.testDisabled = true;

      const data = {
        partnerId: this.partnerId,
        payload: {
          domain: this.boxAndDice.domain,
          apiKey: this.boxAndDice.apiKey,
          merchantId: this.merchantId,
        }
      };

      this.$vs.loading();
      await this.testBoxAndDiceConnection(data).then((result) => {
        this.testDisabled = false;

        let statusCode = result.data.data.statusCode;
        this.testResponseIcon = (statusCode == 200) ? "success" : "error";

        if (statusCode == 200) {
          this.testResponseMessage = "Success!";
          this.isConnectionValid = true;

          if (this.action == "create" && !this.boxAndDice.offices.length) {
            this.boxAndDice.offices.push(...result.data.data.data.filter((item) => {
              item.officeId = item.id,
              item.disbursement = "";
              item.notifications = [{ email: "" }];
              item.remittance = [{ email: "" }];
              return item;
            }));

            if (this.boxAndDice.overrideOfficeSettings) {
              let defaultNotifications = this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item);
              defaultNotifications = defaultNotifications.length ? defaultNotifications.map((item) => { return { email: item } }) : [{ email: "" }];

              let defaultRemittance = this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item);
              defaultRemittance = defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item } }) : [{ email: "" }];

              this.boxAndDice.offices.forEach((item) => {
                if (!item.disbursement && this.boxAndDice.disbursement) {
                  item.disbursement = this.boxAndDice.disbursement;
                }

                item.notifications = JSON.parse(JSON.stringify(defaultNotifications));
                item.remittance = JSON.parse(JSON.stringify(defaultRemittance));
              });
            }
          } else if (this.action == "edit" && (this.isDomainUpdated || this.isApiKeyUpdated || !this.boxAndDice.offices.length)) {
            this.boxAndDice.offices = [...result.data.data.data];
            this.handleDefaultSettings();
          }
        } else if (statusCode == 401) {
          this.testResponseMessage = "Authentication failed";
        } else if (statusCode == 404) {
          this.testResponseMessage = "Incorrect domain";
        } else {
          this.testResponseMessage = `Unknown error - contact ${this.appName} support`;
        }

        this.showIcon = true;
        this.$vs.loading.close();
      }).catch((ex) => {
        let statusCode = ex.data.data.statusCode;

        if (statusCode == 404) {
          this.testResponseMessage = "Incorrect domain";
        } else {
          this.testResponseMessage = `Unknown error - contact ${this.appName} support`;
        }

        this.testDisabled = false;
        this.testResponseIcon = "error";
        this.showIcon = true;
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Test connection",
          text: ex.data.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
      });
    },

    addOfficeNotificationEmail(officeIndex) {
      this.boxAndDice.offices[officeIndex].notifications.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeNotificationEmail(officeIndex) {
      return this.boxAndDice.offices[officeIndex].notifications.length > 1;
    },

    removeOfficeNotificationEmail(officeIndex, index) {
      this.boxAndDice.offices[officeIndex].notifications.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "notificationEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    addOfficeRemittanceEmail(officeIndex) {
      this.boxAndDice.offices[officeIndex].remittance.push({ email: "" });
      this.$forceUpdate();
    },

    deleteOfficeRemittanceEmail(officeIndex) {
      return this.boxAndDice.offices[officeIndex].remittance.length > 1;
    },

    removeOfficeRemittanceEmail(officeIndex, index) {
      this.boxAndDice.offices[officeIndex].remittance.splice(index, 1);
      const key = this.errors.items.findIndex(object => { return object.field === "remittanceEmail" + officeIndex + index });

      if (key >= 0) {
        this.errors.items.splice(key, 1);
      }
      this.$forceUpdate();
    },

    handleDefaultSettings() {
      if (!this.boxAndDice.overrideOfficeSettings) {
        this.boxAndDice.offices = [];
      } else if (this.boxAndDice.overrideOfficeSettings && this.boxAndDice.offices && this.boxAndDice.offices.length) {
        let defaultNotifications = this.boxAndDice.notifications.map(item => { return item.email }).filter((item) => item);
        defaultNotifications = defaultNotifications.length ? defaultNotifications.map((item) => { return { email: item } }) : [{ email: "" }];

        let defaultRemittance = this.boxAndDice.remittance.map(item => { return item.email }).filter((item) => item);
        defaultRemittance = defaultRemittance.length ? defaultRemittance.map((item) => { return { email: item } }) : [{ email: "" }];

        this.boxAndDice.offices.forEach((item) => {
          if (!item.officeId) {
            item.officeId = item.id;
          }

          if (!item.disbursement && this.boxAndDice.disbursement) {
            item.disbursement = this.boxAndDice.disbursement;
          }

          item.notifications = JSON.parse(JSON.stringify(defaultNotifications));
          item.remittance = JSON.parse(JSON.stringify(defaultRemittance));
        });
      } else if (this.boxAndDice.overrideOfficeSettings) {
        this.testConnection();
      }
    },

    validateDomain() {
      const url = new URL(this.boxAndDice.domain);

      if (url.pathname && Object.keys(url.pathname).length > 1) {
        this.errors.add({
          field: "domain",
          msg: "Please only provide domain name"
        });
      }

      this.isDomainUpdated();
    }
  }
};
</script>

